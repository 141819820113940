<template>
<!-- 百姓健身房指数排名 -->
    <div class="userranking darkBack">
        <div class="userrankingnav">
            <div>用户排名</div>
            <div>
                <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
                <span class="riselecte" @click="riselected">月</span>
                <span class="riselecte" @click="riselected">年</span>
                <span class="riselecte" @click="riselected">总</span>
            </div>
        </div>
        <div id="jianoption1">
            <div v-for="(sports, i) in list.sport" :key="i">
                <div class="personal">
                    <span>{{sports.id}}</span>
                    <span style="width:0.38rem;height:0.38rem;border-radius:50%;">
                        <!-- <img src="../../../assets/images/head1.png" alt=""> -->
                        <img :src="sports.image" alt="">
                    </span>
                    <span style="font-size:0.19rem;color:#7EA7E2">{{sports.name}}</span>
                    <span style="width:0.17rem;height:0.17rem;border-radius:50%;">
                        <img :src="sports.gender" alt="">
                    </span>
                </div>
                <div class="timer">{{sports.timer}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            list:window.setData('百姓健身房-无法进入-用户排名', {
                sport:[
                    {id:"1", name: "马诺亚", timer: "432,424分钟", gender: require('../../../assets/images/male.png'), image:require('../../../assets/images/head1.png')},
                    {id:"2", name: "荀永灏", timer: "362,432分钟", gender: require('../../../assets/images/female.png'), image:require('../../../assets/images/head2.png')},
                    {id:"3", name: "赵锐涵", timer: "334,225分钟", image:require('../../../assets/images/head3.png'), gender: require('../../../assets/images/male.png')},
                    {id:"4", name: "马娆雨", timer: "231,562分钟", image:require('../../../assets/images/head4.png'), gender: require('../../../assets/images/female.png')},
                    {id:"5", name: "李兴", timer: "211,116分钟", image:require('../../../assets/images/head5.png'), gender: require('../../../assets/images/female.png')},
                    {id:"6", name: "荀永灏", timer: "362,432分钟", image:require('../../../assets/images/head6.png'), gender: require('../../../assets/images/male.png')},
                    {id:"7", name: "赵锐涵", timer: "334,225分钟", image:require('../../../assets/images/head7.png'), gender: require('../../../assets/images/male.png')},
                    {id:"8", name: "马娆雨", timer: "231,562分钟", image:require('../../../assets/images/head8.png'), gender: require('../../../assets/images/male.png')},
                    {id:"9", name: "李兴",timer: "211,116分钟", image:require('../../../assets/images/head9.png'), gender: require('../../../assets/images/male.png')},
                    {id:"10", name: "马娆雨", timer: "231,562分钟", image:require('../../../assets/images/head10.png'), gender: require('../../../assets/images/female.png')},
                    {id:"11", name: "李兴", timer: "211,116分钟", image:require('../../../assets/images/head11.png'), gender: require('../../../assets/images/female.png')},
                ],
            }),
            
        }
    },
    methods: {
        riselected(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.riselecte').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            var switchover = e.target.innerText
        },
        
    },
    mounted () {

    }
}
</script>

<style scoped>
.userranking{
    width:5.94rem;
    height:6.40rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
.userrankingnav{
    display: flex;
    justify-content: space-between;
}


.riselecte{
    width:33%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.riselecte:nth-child(1){
    background-color:#7EA7E2;
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    color:#000000;
}
.riselecte:nth-child(2){
    /* background:red; */
    border-left:0.01rem solid #838181;
    border-right:0.01rem solid #838181;
}
.riselecte:nth-child(3){
    /* background:red; */
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}


.userrankingnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}
#jianoption1{
    width:5.0rem; 
    height:5.6rem;
    /* border:1px solid red; */
    font-size: 0.17rem;
    color:#999999;
    margin:auto;
    margin-top:0.2rem;
}
#jianoption1>div{
    height:0.46rem;
    width:100%;
    /* border:1px solid red; */
    margin-bottom:0.03rem;
}

.personal{
    height:0.46rem;
    line-height: 0.46rem;;
    /* border:1px solid red; */
    float: left;
    vertical-align: middle;
}
.personal>span{
    display: inline-block;
    line-height: 0.46rem;
}
.personal>span:nth-child(1){
    width:0.2rem;
}
.personal>span:nth-child(2){
    margin:0rem 0.43rem;
}
/* .personal>span:nth-child(3){
    width:0.8rem;
} */
.personal>span:nth-child(4){
    margin-left:0.09rem;
}
.personal img{
    width:100%;
    height:100%;
    vertical-align: middle;
}
.timer{
    float: right;
    line-height: 0.46rem;
}
</style>